import React from "react";
import "../Css/Home.css";
import img10 from "../Image/break-649351_1920.jpg";
import Page from "./Page";
const Contact = () => {
  return (
    <div>
      <Page
        title="Contact-VKS Organization"
        description=" Have questions about our awesome interior design work? Contact us through our contact page. We're here to help you create a beautiful and functional space."
        keywords="interior office space , interior design office design , corporate office interior designers , best office interiors , interior design for workspace , the office interior company , architecture interior design office , best corporate office interior design , it company office interior design , best office room design"
        canonicalUrl="https://www.yoursite.com/Contact"
      >
      </Page>
      <div>
        <main id="main">
          <div
            className="breadcrumbs d-flex align-items-center"
            style={{ backgroundImage: `url(${img10})` }}
          >
            <div
              className="container position-relative d-flex flex-column align-items-center"
              data-aos="fade"
            >
              <h2>Contact</h2>
              <h5 style={{ color: "white" }}>Home/Contact</h5>
            </div>
          </div>
          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
              <div className="row gy-4">
                <div className="col-lg-6">
                  <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-map bounce"></i>
                    <h3>India : VKS Organization</h3>
                    <p>53 Raksha Colony,
                      Near Fire Engineering Colony, Nagpur
                      India</p>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6">
                  <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-whatsapp bounce"></i>

                    <button className="navbttn " style={{ height: "40px", letterSpacing: "0", marginTop: "2.4rem" }}>
                      <a href="mailto:vks_orgnz@rediffmail.com" style={{ color: "white" }} > Email Us </a>
                    </button>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6">
                  <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-telephone bounce"></i>
                    <h3>Call Us</h3>
                    <h5>7447491009 / 7447499199</h5>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-whatsapp bounce"></i>

                    <button className="navbttn " style={{ height: "40px", letterSpacing: "0", marginTop: "2.4rem" }}>
                      <a href="https://wa.me/917447491009" style={{ color: "white" }} > Message on Whatsapp</a>
                    </button>
                  </div>
                </div>
              </div>

              <div className="row gy-4 mt-1">
                <div className="col-lg-6 ">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1860.1953770026798!2d79.03624353843435!3d21.17663079621264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s53%20Raksha%20Colony%2C%20Near%20Fire%20Engineering%20Colony%2C%20Nagpur%20India!5e0!3m2!1sen!2sin!4v1702107389078!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>

                <div className="col-lg-6">
                  <form
                    action="forms/contact.php"
                    method="post"
                    role="form"
                    className="php-email-form"
                  >
                    <div className="row gy-4">
                      <div className="col-lg-6 form-group">
                        <input
                          style={{ border: "1px solid #ced4da" }}
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          required
                        ></input>
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          style={{ border: "1px solid #ced4da" }}
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          required
                        ></input>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        style={{ border: "1px solid #ced4da" }}
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required
                      ></input>
                    </div>
                    <div className="form-group">
                      <textarea
                        style={{ border: "1px solid #ced4da" }}
                        className="form-control"
                        name="message"
                        rows="5"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Contact;
