import React, { useState, useEffect, Link } from "react";
import "../Css/Nav.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import img from '../Image/Dark_Beige_Modern_Real_Estate_Bu.png'

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 10;
      setScrolling(isScrolling);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const initialClickedItem = JSON.parse(localStorage.getItem('clickedItem')) || "home";

  const [clickedItem, setClickedItem] = useState(initialClickedItem);

  // Update local storage whenever clickedItem changes
  useEffect(() => {
    localStorage.setItem('clickedItem', JSON.stringify(clickedItem));
  }, [clickedItem]);

  // console.log(clickedItem)


  const handleItemClick = (item) => {
    setShowMediaIcons(!showMediaIcons)
    setClickedItem(item);
  };


  return (
    <div className={`navbarr ${scrolling ? "scrolling" : ""}`}>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
          <div className="fotteralign">
            <div className="fotter">
              <img src={img} className="logoo"></img>

            </div>
          </div>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <div className="d-flex justify-content-end cross d-none">
            <div className="cross" style={{ color: "black", marginRight: "2rem", marginTop: "rem1" }} onClick={() => setShowMediaIcons(!showMediaIcons)}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg></div>
          </div>
          <ul>
            <li>
              <NavLink
                to="/"
                className={`nav-item ${clickedItem === "home" ? "activee" : "notactivee"
                  }`}
                onClick={() => handleItemClick("home")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={`nav-item ${clickedItem === "about" ? "activee" : "notactivee"
                  }`}
                onClick={() => handleItemClick("about")}
              >
                About
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Project"
                className={`nav-item ${clickedItem === "Project" ? "activee" : "notactivee"
                  }`}
                onClick={() => handleItemClick("Project")}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/ClientsTestimonial"
                className={`nav-item ${clickedItem === "ClientsTestimonial"
                  ? "activee"
                  : "notactivee"
                  }`}
                onClick={() => handleItemClick("ClientsTestimonial")}
              >
                Testimonial
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={`nav-item ${clickedItem === "contact" ? "activee" : "notactivee"
                  }`}
                onClick={() => handleItemClick("contact")}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          {/* <ul className="social-media-desktop">
            <li>
              <button className="navbtn">
                BOOKING {"  "} <i className="bi bi-chevron-right"></i>
              </button>
            </li>
          </ul> */}

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>

    </div>
  );
};

export default Navbar;
