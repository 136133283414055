import React from 'react';
import { Helmet } from 'react-helmet';

const Page = ({ title, description, keywords , canonicalUrl}) => {
  return (
    <div>
      <Helmet>
        <title>{`${title}`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
    </div>
  );
};

export default Page;