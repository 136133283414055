import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import React, { Component } from "react";
import About from "./Component/About";
import Contact from "./Component/Contact";
import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import Project from "./Component/Project";
import ClientsTestimonial from "./Component/ClientsTestimonial";
import Footer from "./Component/Footer";


const App = ()=> {

    return (
      <BrowserRouter>
        <Navbar/>
        <div>
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route
              path="/About"
              element={
              <About />
              }
            ></Route>
            {/* login first */}
            <Route
              path="/Contact"
              element={
               <Contact />
              }
            ></Route>
            <Route
              path="/ClientsTestimonial"
              element={
                
                  <ClientsTestimonial />
                
              }
            ></Route>

            <Route
              path="/Project"
              element={
                <Project />
              }
            ></Route>

            <Route path="*" element={<h1>404 page not found!</h1>}></Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    );
  }


export default App;
