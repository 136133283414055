import React, { Component } from "react";
import "../Css/Home.css";
import Img from "../Image/befor1.jpg";
import Img2 from "../Image/befor1.jpg";
import Img3 from "../Image/befor1.jpg";
import img4 from "../Image/vksmain-1.jpg";
import img5 from "../Image/vksmain3.jpg";
import img6 from '../Image/vksmain2 (1).jpg';
import img7 from "../Image/vksmain9.jpg";
import img8 from "../Image/vksmain10.jpg";
import img9 from "../Image/vksmain11.jpg"
import img10 from "../Image/break-649351_1920.jpg";
import img11 from "../Image/office_img3.png"
import img12 from "../Image/before_2.jpg"
import Video from "../Video/LUXURY INTERIOR DESIGN_ for spending the best life.mp4"
import "../Css/Project.css"
import Page from "./Page";
const Project = () => {
  

  return (
    <div>
       <Page
      title="Projects-VKS Organization"
      description="Discover VKS Organization's office Interior Design Projects through images. Our project page showcases the beauty and functionality we bring to spaces. Explore a gallery of stunning interiors, each reflecting our commitment to excellence. Let the images speak for themselves, telling stories of inspired design and transformation"
      keywords="best interior for office , best office room interior design , best workplace interior design , company office interior design , corporate interior office design , corporate office space design , desain interior office , design for office interior , good office interior design , interior corporate office"
      canonicalUrl="https://www.yoursite.com/project"
    >
    </Page>
      <header id="header" className="headeralign-items-center"></header>

      <main id="main">
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: `url(${img10})` }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Projects</h2>
            <h5 style={{ color: "white" }}>Home/Project</h5>
          </div>
        </div>

        <section id="projects" className="projects">
          <div className="container" data-aos="fade-up">
            <div
              className="portfolio-isotope"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
            >
        <div
          id="recent-blog-posts"
          className="recent-blog-posts  section-bg-mr "
        >
          <div className="container" data-aos="fade-up">
            <div className=" section-header">
              <h2 className="home_title">All Project</h2>
        
            </div>


          </div>
        </div>

              <div
                className="row gy-4 portfolio-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >

<div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                  <div className="portfolio-content h-100">
                    <img
                      src={img7}
                      className="img-fluid"
                      alt=""
                    ></img>
                    <div className="portfolio-info">
                      <h4>Updated Office Interior</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img7}
                        title="Construction 2"
                        data-gallery="portfolio-gallery-construction"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      {/* <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                  <div className="portfolio-content h-100">
                    <img
                      src={img8}
                      className="img-fluid"
                      alt=""
                    ></img>
                    <div className="portfolio-info">
                    <h4>Updated Office Interior</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img8}
                        title="Repairs 2"
                        data-gallery="portfolio-gallery-repairs"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      {/* <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                  <div className="portfolio-content h-100">
                    <img
                      src={img9}
                      className="img-fluid"
                      alt=""
                    ></img>
                    <div className="portfolio-info">
                    <h4>Updated Office Interior</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img9}
                        title="Repairs 2"
                        data-gallery="portfolio-gallery-book"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      {/* <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                  <div className="portfolio-content h-100">
                    <img
                      src={img4}
                      className="img-fluid"
                      alt=""
                    ></img>
                    <div className="portfolio-info">
                    <h4>Updated Office Interior</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img4}
                        title="Repairs 1"
                        data-gallery="portfolio-gallery-repairs"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      {/* <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                  <div className="portfolio-content h-100">
                    <img
                      src={img5}
                      className="img-fluid"
                      alt=""
                    ></img>
                    <div className="portfolio-info">
                    <h4>Updated Office Interior</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img5}
                        title="Repairs 1"
                        data-gallery="portfolio-gallery-book"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      {/* <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div className="portfolio-content h-100">
                    <img
                      src={img6}
                      className="img-fluid"
                      alt=""
                    ></img>
                    <div className="portfolio-info">
                    <h4>Updated Office Interior</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img6}
                        title="Remodeling 2"
                        data-gallery="portfolio-gallery-remodeling"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      {/* <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a> */}
                    </div>
                  </div>
                </div>

    

          
                <div
          id="recent-blog-posts"
          className="recent-blog-posts  section-bg-mr "
        >
          <div className="container mt-5" data-aos="fade-up">
            <div className=" section-header">
              <h2 className="home_title" style={{margin:"0"}}>Before & After</h2>
        
            </div>


          </div>
        </div>
                <div className="col-lg-6 col-md-6 portfolio-item filter-remodseling">
                  <div className=" portfolio-content h-100">
                    <img src={Img} className="img-fluid" alt=""></img>
                    
                    <div className="portfolio-infoo">
                      <h4>After</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={Img}
                        title="Remodeling 1"
                        data-gallery="portfolio-gallery-remodeling"
                        className="glightbox preview-link"
                      >
                        {/* <i className="bi bi-zoom-in"></i> */}
                      </a>
                      <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        {/* <i className="bi bi-link-45deg"></i> */}
                      </a>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-6 col-md-6 portfolio-item filter-remodseling">
                  <div className=" portfolio-content h-100">
                    <img src={img12} className="img-fluid" alt=""></img>
                    
                    <div className="portfolio-infoo2">
                      <h4>After</h4>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                      <a
                        href={img4}
                        title="Remodeling 1"
                        data-gallery="portfolio-gallery-remodeling"
                        className="glightbox preview-link"
                      >
                        {/* <i className="bi bi-zoom-in"></i> */}
                      </a>
                      <a
                        href="project-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        {/* <i className="bi bi-link-45deg"></i> */}
                      </a>
                    </div>
                  </div>
                </div>
              
            
              </div>




            </div>
          </div>
          <div
          id="recent-blog-posts"
          className="recent-blog-posts  section-bg-mr "
        >
          <div className="container mt-5" data-aos="fade-up">
            <div className=" section-header">
              <h2 className="home_title" style={{margin:"0"}}> Video</h2>
              <div className="col-md-6 offset-md-3 mt-5">
  {/* <video  style={{width:"100%"}} controls>
    <source  src={Video} type="video/mp4"></source>

  </video> */}
            <div style={{ display: "flex", justifyContent: "center"}}>
            <div className="flowhidden">
 <div className="containerr_video " style={{display:"flex" , justifyContent:"center"}}> 
 <img src={img11} style={{height:"20rem"}} className="img-fluid"></img>
<a id="play-video" className="video-play-button centered" href={Video}>
  <span></span>
</a> 
</div>
</div> </div> 
</div>
            </div>


          </div>
        </div>
          {/* <div style={{ margin: "5rem" , marginTop:"0"}}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/zumJJUL_ruM?si=6KMAZUwtFjq4gT8g"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div> */}
        </section>
      </main>
    </div>
  );
};

export default Project;
