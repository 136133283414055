import React, { useState, useEffect } from "react";
import img5 from "../Image/vksmain10.jpg";
import img7 from "../Image/vksmain2 (2).jpg";
import img9 from "../Image/office_img3.png";
import img8 from "../Image/vksmain5.jpg";
import img11 from "../Image/vksmain6 (1).jpg";
import img10 from "../Image/break-649351_1920.jpg";
import img12 from "../Image/vksmain3 (1).jpg";
import img13 from "../Image/vksmain7 (1).jpg";
import img14 from "../Image/vksmain4 (1).jpg";
import "../Css/Fade.css";
import PureCounter from "@srexi/purecounterjs";
// import avtar from "../Image/avtar.jpg";
// import avtar2 from "../Image/avtar 2.png";
// import avtar3 from "../Image/avtar 3.png";
import img1 from "../Image/icons8-stool-24.png";
import "../Css/Home.css";
import Carousel from "react-bootstrap/Carousel";
import Video from "../Video/LUXURY INTERIOR DESIGN_ for spending the best life.mp4";
import Page from "./Page";
const About = () => {
  useEffect(() => {
    new PureCounter({ selector: ".purecounter" });
  }, []);
  return (



    <div>
      <Page
        title="About-VKS Organization"
        description="We make spaces look beautiful and work well. Check out our page to see how we turn design ideas into fantastic living spaces. With VKS Organization, your office can be both stylish and practical. Come and discover the magic of great design with us!"
        keywords="office fitout company , best office interior design , office room interior design , office decorator , workplace interior design , office space interior designers , office interior decorator , office interior company , workspace interior design , office decoration design"
        canonicalUrl="https://www.yoursite.com/about"
      >
      </Page>

      <main id="main">
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: `url(${img10})` }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>About</h2>
            <h5 style={{ color: "white" }}>Home/About</h5>
          </div>
        </div>

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row position-relative">
              <div
                className="col-lg-6 about-img"
                style={{
                  backgroundImage: `url(${img5})`,
                  // height: "100%",
                }}
              ></div>

              <div className="col-lg-6">
                {/* <h2>Consequatur eius et magnam</h2> */}
                <div className="our-story">
                  <h3>Our Story</h3>
                  <h5 style={{ fontSize: "19px" }}>
                    VKS Organization, founded with a passion for transforming
                    spaces, embarked on a journey to redefine interiors with
                    creativity and functionality.
                  </h5>
                  <ul>
                    <li style={{ display: "flex", alignItems: "start" }}>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span style={{ fontSize: "15px" }}>
                        Owner Name: Kanchan Gedam.
                      </span>
                    </li>
                    <li style={{ display: "flex", alignItems: "start" }}>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span style={{ fontSize: "15px" }}>
                        Firm : VKS Organization.
                      </span>
                    </li>

                    <li style={{ display: "flex", alignItems: "start" }}>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span style={{ fontSize: "15px" }}>
                        Category : Turnkey Contractor (Government & Corporate).
                      </span>
                    </li>
                    <li style={{ display: "flex", alignItems: "start" }}>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span style={{ fontSize: "15px" }}>
                        Origin: Born out of a vision to elevate interior design
                        standards.
                      </span>
                    </li>
                    {/* <li style={{ display: "flex", alignItems: "start" }}>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span style={{ fontSize: "15px" }}>
                        work experience : Pan INDIA MSETCL / MOIL / MECL / WCL / BANKs / ROYAL Sundaram . We are Turnkey Contractor for office renovation and upgradation which includes Civil,Interior,(work stations,cubicles), electrical Automation and Audio Video Solutions.Our basic elements are Design / Technology / Supplies and Work completion.We are one stop for Lump Sum Turnkey at a fixed price and handing over for business operations in given time limit.
                      </span>
                    </li> */}
                  </ul>
                  {/* <p style={{ fontSize: "13px" }}>
                    Vitae autem velit excepturi fugit. Animi ad non. Eligendi et
                    non nesciunt suscipit repellendus porro in quo eveniet.
                    Molestias in maxime doloremque.
                  </p> */}

                  {/* <div className="watch-video d-flex align-items-center position-relative">
                    <i className="bi bi-play-circle"></i>
                    <a
                      href={Video}
                      className="glightbox stretched-link"
                    >
                      Watch Video
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* counter */}
        <div className="containerr section-bg-mr">
          <div className="background-image"></div>
          <div className="text">
            <h1 className="experienceh1">
              {" "}
              {/* <i className="bi bi-quote quote-icon-left"></i> */}
            </h1>
            <section id="stats-counter" className="stats-counter ">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-4 col-md-7">
                    <div className="stats-item d-flex align-items-center w-100 h-100">
                      <i className="bi bi-emoji-smile color-blue flex-shrink-0"></i>
                      <div>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="12"
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <h6 style={{ fontSize: "14px" }}>
                          No of years in service
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-7">
                    <div className="stats-item d-flex align-items-center w-100 h-100">
                      <i className="bi bi-journal-richtext color-orange flex-shrink-0"></i>
                      <div style={{ padding: "20px" }}>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end={76}
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <h6 style={{ fontSize: "14px" }}>Projects</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-7">
                    <div className="stats-item d-flex align-items-center w-100 h-100">
                      <i className="fa-solid fa-city"></i>
                      <div>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="10"
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <h6 style={{ fontSize: "14px" }}>No of City's</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* services */}
        <section id="alt-services" className="alt-services">
          <div className=" section-header">
            <h2 className="home_title home_title_our_services ">OUR SERVICES</h2>
          </div>
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-around gy-4">
              <div
                className="col-lg-6 img-bg"
                style={{ overflow: "hidden" }}
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                {" "}
                <Carousel>
                  {/* 1 */}
                  <Carousel.Item>
                    <img src={img8} className="img-curosel"></img>
                    <Carousel.Caption>
                      {/* <h3>First slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  {/* 2 */}
                  <Carousel.Item>
                    <img className="img-curosel" src={img7}></img>
                    <Carousel.Caption>
                      {/* <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  {/* 3 */}
                  <Carousel.Item>
                    <img className="img-curosel" src={img11}></img>
                    <Carousel.Caption>
                      {/* <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  {/* 4 */}
                  <Carousel.Item>
                    <img src={img12} className="img-curosel"></img>
                    <Carousel.Caption>
                      {/* <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  {/* 5 */}
                  <Carousel.Item>
                    <img src={img13} className="img-curosel"></img>
                    <Carousel.Caption>
                      {/* <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  {/* 6 */}
                  <Carousel.Item>
                    <img className="img-curosel" src={img14}></img>
                    <Carousel.Caption>
                      {/* <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>

              <div className="col-lg-5 d-flex flex-column justify-content-center">
                {/* <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3>
                <p>
                  Esse voluptas cumque vel exercitationem. Reiciendis est hic
                  accusamus. Non ipsam et sed minima temporibus laudantium.
                  Soluta voluptate sed facere corporis dolores excepturi
                </p> */}
                {/* first */}
                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <i className="bi bi-patch-check flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        TURNKEY PROJECT MANAGEMENT
                      </a>
                    </h4>
                    {/* <p>
                      Voluptatum deleniti atque corrupti quos dolores et quas
                      molestias excepturi sint occaecati cupiditate non
                      provident
                    </p> */}
                  </div>
                </div>
                {/* second */}
                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <i className="fa-solid fa-house"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        INTERIOR DESIGN
                      </a>
                    </h4>
                    {/* <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                    </p> */}
                  </div>
                </div>
                {/* third */}
                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <i className="bi bi-easel flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        PROJECT MANAGEMENT CONSULTANCY
                      </a>
                    </h4>
                    {/* <p>
                      Explicabo est voluptatum asperiores consequatur magnam. Et
                      veritatis odit. Sunt aut deserunt minus aut eligendi omnis
                    </p> */}
                  </div>
                </div>
                {/* fourth */}
                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <i>
                    <span
                      style={{ fontSize: "4rem" }}
                      className="material-symbols-outlined"
                    >
                      location_city
                    </span>
                  </i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        LANDSCAPING
                      </a>
                    </h4>
                    {/* <p>
                      Est voluptatem labore deleniti quis a delectus et. Saepe
                      dolorem libero sit non aspernatur odit amet. Et eligendi
                    </p> */}
                  </div>
                </div>
                {/* fifth */}
                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <i className="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        DESIGN CONSULTANCY
                      </a>
                    </h4>
                    {/* <p>
                      Est voluptatem labore deleniti quis a delectus et. Saepe
                      dolorem libero sit non aspernatur odit amet. Et eligendi
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="alt-services-2" className="alt-services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-around gy-4">
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <h3>
                  Non quasi officia eum nobis et rerum epudiandae rem voluptatem
                </h3>
                <p>
                  Maxime quia dolorum alias perspiciatis. Earum voluptatem sint
                  at non. Ducimus maxime minima iste magni sit praesentium
                  assumenda minus. Amet rerum saepe tempora vero.
                </p>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bi bi-easel flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        Lorem Ipsum
                      </a>
                    </h4>
                    <p>
                      Voluptatum deleniti atque corrupti quos dolores et quas
                      molestias excepturi sint occaecati cupiditate non
                      provident
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bi bi-patch-check flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        Nemo Enim
                      </a>
                    </h4>
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        Dine Pad
                      </a>
                    </h4>
                    <p>
                      Explicabo est voluptatum asperiores consequatur magnam. Et
                      veritatis odit. Sunt aut deserunt minus aut eligendi omnis
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" className="stretched-link">
                        Tride clov
                      </a>
                    </h4>
                    <p>
                      Est voluptatem labore deleniti quis a delectus et. Saepe
                      dolorem libero sit non aspernatur odit amet. Et eligendi
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 img-bg"
                style={{
                  backgroundImage: `url(${img8})`,
                }}
                data-aos="zoom-in"
                data-aos-delay="100"
              ></div>
            </div>
          </div>
        </section> */}

        {/* Who are we */}

        <section className="section-bg">
          <div className=" section-header ">
            <h2 className="home_title home_title_our_services">WHO ARE WE</h2>
          </div>

          {/* mission */}

          <div
            id="recent-blog-posts"
            className="recent-blog-posts  section-bg-mr "
          >
            <div className="container" data-aos="fade-up">
              <div className=" section-header ">
                <h3
                  className="home_title"
                  style={{ color: "black", marginBottom: "0px" }}
                >
                  Mission
                </h3>
                <p className="h4 experienceh1">
                  <i className="bi bi-dot"></i>
                </p>
                <h4 className="mt-5">
                  Deliver a cutting edge office design by blending elegance and
                  classy that provide high functionality and sustainability
                  creating a go to lace for work.
                </h4>
              </div>
            </div>
          </div>
          {/* video */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="flowhidden">
              <div
                className="containerr_video "
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={img9}
                  style={{ height: "20rem" }}
                  className="img-fluid"
                ></img>
                <a
                  id="play-video"
                  className="video-play-button centered"
                  href={Video}
                >
                  <span></span>
                </a>
              </div>
            </div>{" "}
          </div>

          {/* <div className="col-md-6 offset-md-3 mt-5">
  <video  style={{width:"100%"}} className="" controls>
    <source  src={Video} type="video/mp4"></source>

  </video>
</div> */}
          {/* </div> */}
          <div id="video-overlay" className="video-overlay">
            <a className="video-overlay-close"></a>
          </div>
          {/* vision */}
          <div id="recent-blog-posts" className="recent-blog-posts mt-5  ">
            <div className="container" data-aos="fade-up">
              <div className=" section-header">
                <h3
                  className="home_title"
                  style={{ color: "black", marginBottom: "0px" }}
                >
                  Vision
                </h3>
                <p className="h4 experienceh1">
                  <i className="bi bi-dot"></i>
                </p>
                <h4 className="mt-5">
                  {" "}
                  To be the firm of choice for our clients
                </h4>
              </div>
            </div>
          </div>
        </section>


        {/* work experience */}

        <section
          id="recent-blog-posts"
          className="recent-blog-posts  section-bg-mr "
        >
          <div className="container" data-aos="fade-up">
            <div className=" section-header">
              <h2 className="home_title">work experience</h2>
              <h4 style={{ letterSpacing: ".5px" }}>
                Pan INDIA  <b>MSETCL / MOIL / MECL / WCL / BANKs / ROYAL Sundaram </b>. We are Turnkey Contractor for office renovation and upgradation which includes Civil,Interior,(work stations,cubicles),  electrical Automation and Audio Video Solutions.Our basic elements are <b> Design / Technology / Supplies </b> and Work completion.We are one stop for Lump Sum Turnkey at  a fixed price and handing over for business operations in given time limit.
              </h4>
            </div>
          </div>
        </section>


        {/* why us */}
        <section
          id="recent-blog-posts"
          className="recent-blog-posts  section-bg-mr section-bg"
        >
          <div className="container" data-aos="fade-up">
            <div className=" section-header">
              <h2 className="home_title">WHY US</h2>
              <h4>
                Our strength is a result of a singular focus on our client’s
                needs, the alliances we’ve forged and the networks we have
                created. Our comprehensive service program offers a wide range
                of choices to enable us to choose and customise services for
                each facet of our customer’s requirements.{" "}
              </h4>
            </div>
          </div>
        </section>

        {/* our team */}
        {/* <section id="team" className="team section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Team</h2>
              <p>
                Aperiam dolorum et et wuia molestias qui eveniet numquam nihil
                porro incidunt dolores placeat sunt id nobis omnis tiledo stran
                delop
              </p>
            </div>

            <div className="row gy-5 ">
              <div
                className="col-lg-4 col-md-6 member aboutshadow"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member-img">
                  <img
                    src={avtar}
                    className="img-fluid "
                    alt=""
                  ></img>
                  <div className="social">
                    <a href="#">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info text-center">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
               
                  <p className="ourteam">
                    Aliquam iure quaerat voluptatem praesentium possimus unde
                    laudantium vel dolorum distinctio dire flow
                  </p>
           
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member aboutshadow"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="member-img">
                  <img
                    src={avtar2}
                    className="img-fluid bg-white"
                    alt=""
                  ></img>
                  <div className="social">
                    <a href="#">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>


                
                <div 
                className="member-info text-center">
                  <h4>Sarah Jhonson</h4>
                  <span>Product Manager</span>
                  <p className="ourteam">
                    Labore ipsam sit consequatur exercitationem rerum laboriosam
                    laudantium aut quod dolores exercitationem ut
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member aboutshadow"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="member-img">
                  <img
                src={avtar3}
                    className="img-fluid"
                    alt=""
                  ></img>
                  <div className="social">
                    <a href="#">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info text-center">
                  <h4>William Anderson</h4>
                  <span>CTO</span>
                  <p className="ourteam">
                    Illum minima ea autem doloremque ipsum quidem quas
                    aspernatur modi ut praesentium vel tque sed facilis at qui
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member aboutshadow"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="member-img">
                  <img
                    src="assets/img/team/team-4.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                  <div className="social">
                    <a href="#">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info text-center">
                  <h4>Amanda Jepson</h4>
                  <span>Accountant</span>
                  <p className="ourteam">
                    Magni voluptatem accusamus assumenda cum nisi aut qui
                    dolorem voluptate sed et veniam quasi quam consectetur
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member aboutshadow"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="member-img">
                  <img
                    src="assets/img/team/team-5.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                  <div className="social">
                    <a href="#">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info text-center">
                  <h4>Brian Doe</h4>
                  <span>Marketing</span>
                  <p className="ourteam">
                    Qui consequuntur quos accusamus magnam quo est molestiae
                    eius laboriosam sunt doloribus quia impedit laborum velit
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member aboutshadow"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="member-img">
                  <img
                    src="assets/img/team/team-6.jpg"
                    className="img-fluid"
                    alt=""
                  ></img>
                  <div className="social">
                    <a href="#">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
                <div className="member-info text-center">
                  <h4>Josepha Palas</h4>
                  <span>Operation</span>
                  <p className="ourteam">
                    Sint sint eveniet explicabo amet consequatur nesciunt error
                    enim rerum earum et omnis fugit eligendi cupiditate vel
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </div>
  );
};

export default About;
