import React, { useState, useEffect } from "react";
import img from "../Image/vksmain6.jpg";
import img2 from "../Image/img2.JPEG";
// import img3 from "../Image/hotel-1749602_1920.jpg";
// import img4 from "../Image/office_img2.jpg";
// import img5 from "../Image/living-room-2732939_1920.jpg";
// import img6 from "../Image/warehouse-3843997_1920.jpg";
import img7 from "../Image/homeslider2.avif";
import img8 from "../Image/homeslider3.jpg";
// import img9 from "../Image/homeslider4.jpg";
import img10 from "../Image/office_img3.png";
import img11 from "../Image/Bitmap.png";
import img12 from "../Image/Bitmap (2).png";
import img13 from "../Image/Bitmap (3).png";
import img14 from "../Image/Bitmap (4).png";
import img15 from "../Image/Bitmap (5).png";
import img16 from "../Image/Bitmap (6).png";
import img17 from "../Image/Bitmap (7).png";
import img18 from "../Image/Bitmap__8_-removebg-preview.png";
import img19 from "../Image/Bitmap__9_-removebg-preview-modified.png";
import img20 from "../Image/Bitmap__10_-removebg-preview.png";
import img21 from "../Image/Bitmap__11_-removebg-preview.png";
import img22 from "../Image/Bitmap__12_-removebg-preview.png";
import img23 from "../Image/Bitmap__13_-removebg-preview.png";
import img24 from "../Image/icons8-correct-25.png";
// import img25 from "../Image/Bitmap (14).png";
// import img26 from "../Image/Bitmap (15).png";
// import img27 from "../Image/Bitmap (16).png";
// import img28 from "../Image/Bitmap (17).png";
// import img29 from "../Image/Bitmap (18).png";
import img30 from "../Image/Bitmap (19).png";
import img31 from "../Image/office_img3.png";
import img32 from "../Image/vksmain-1.jpg";
import img33 from "../Image/vksmain3.jpg";
import img34 from "../Image/vksmain2.jpg";
import img35 from "../Image/vksmain9.jpg";
import Video from "../Video/LUXURY INTERIOR DESIGN_ for spending the best life.mp4";
import "../Css/Home.css";
import PureCounter from "@srexi/purecounterjs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Css/Services.css";
import Page from "./Page";
import img36 from "../Image/construction.mp4"
const Home = () => {
  // const [isHovered, setIsHovered] = useState(false);
  // const [isHovered2, setIsHovered2] = useState(false);
  // const [isHovered3, setIsHovered3] = useState(false);
  // const [isHovered4, setIsHovered4] = useState(false);

  const [rating, setRating] = useState(5);

  const content =
    "We are really good at making offices look awesome by mixing cool style with practicality. They pay close attention to details and use creative ideas to turn workspaces into inspiring places. From planning to making it happen, VSK Organization creates custom and classy office designs that make the whole work experience better.";
  const content2 =
    "We are great at making conference halls look fantastic by designing their interiors. They focus on creating comfortable seating arrangements and adding modern elements to enhance the overall atmosphere. VSK brings a touch of style and functionality to ensure conference spaces are both visually appealing and practical for effective meetings.";
  const content3 =
    "We are makes training rooms look really good by designing their insides. They focus on making the space comfy and inspiring for effective learning. With careful attention to detail, VSK ensures the training rooms not only look nice but also work well for a better learning experience.";
  const content4 =
    "We are really good at making canteens look better with their interior design skills. They make the dining areas inviting and stylish, so people enjoy their meals in a cool space. VSK knows how to transform canteens into nice places that feel modern and comfortable.";
  const content5 =
    "We are expert at making offices look great with their interior design work. They create stylish and functional workspaces, blending modern aesthetics with practicality. VSK transforms offices into inviting environments, ensuring a comfortable and productive atmosphere.";
  const content6 =
    "We are really good at making big stores look awesome inside. They design the insides to be modern and welcoming, making shopping easier and more enjoyable with a focus on both style and functionality, they create layouts that optimize shopping experiences. VSK knows how to make mega stores cool and practical for today's shoppers.";

  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);
  const [showMore5, setShowMore5] = useState(false);
  const [showMore6, setShowMore6] = useState(false);

  const toggleShowMore = (itemNumber) => {
    switch (itemNumber) {
      case 1:
        setShowMore1(!showMore1);
        break;
      case 2:
        setShowMore2(!showMore2);
        break;
      case 3:
        setShowMore3(!showMore3);
        break;
      case 4:
        setShowMore4(!showMore4);
        break;
      case 5:
        setShowMore5(!showMore5);
        break;
      case 6:
        setShowMore6(!showMore6);
        break;
      default:
        break;
    }
  };

  // slider

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // slider end

  const handleStarClick = (clickedRating) => {
    setRating(clickedRating);
  };
  useEffect(() => {
    new PureCounter({ selector: ".purecounter" });
  }, []);
  return (
    <div>
      <Page
        title="Home-VKS Organization"
        description="We do office fitout interior designing for offices , Malls , Conference Halls , Training Rooms , Canteen Renovation , Office Interiors , Mega Stores etc."
        keywords="interior designing for offices , best office interior designing company ,   best office interior designing company in nagpur , office interior , office space design , office room design , interior designers for office space , office interior design company , interior designers for office , office fitout design , office space interior design , corporate office interior design"
        canonicalUrl="https://www.yoursite.com/home"
      ></Page>

      <div className="contsinerfluid ">
        <section id="hero" className="hero ">
          <div className="info d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  <h4 data-aos="fade-down">
                    <div className="star-ratingg">
                      <div className="flip-cover-dribbble"></div>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          className={
                            star <= rating ? "star-filled" : "star-empty"
                          }
                          onClick={() => handleStarClick(star)}
                        >
                          &#9733; {/* Unicode character for a star */}
                        </span>
                      ))}
                    </div>
                  </h4>
                  <h2 data-aos="fade-up " className="animate-charcter">
                    Enjoy a Luxary Experience
                  </h2>
                  {/* <a
                  data-aos="fade-up"
                  data-aos-delay="200"
                  href="#get-started"
                  className="btn-get-started"
                >
                  Book now {"  "}{" "}
                  <i className="bi bi-chevron-right experienceh11"></i>
                </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            id="hero-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="5000"
          >
            <div
              className="carousel-item active"
              style={{
                backgroundImage: `url(${img33})`,
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${img7})`,
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${img8})`,
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${img35})`,
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${img10})`,
              }}
            ></div>

            <a
              className="carousel-control-prev"
              href="#hero-carousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              ></span>
            </a>

            <a
              className="carousel-control-next"
              href="#hero-carousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </section>

        <main id="main">


          <section id="get-started" className="get-started  section-bg-mr" style={{ backgroundColor: "white" }}>
            <div className="container" style={{ backgroundColor: "white" }}>
              <div className="row justify-content-between gy-4" style={{ backgroundColor: "white" }}>
                <div
                  className="col-lg-7 d-flex align-items-center"
                  data-aos="fade-up"
                >
                  <div className="content">
                    <img className="img" src={img} alt="img"></img>
                    <img className="img2" src={img2} alt="img"></img>
                  </div>
                </div>

                <div className="col-lg-5 align-items-center" data-aos="fade" style={{ backgroundColor: "white" }}>
                  <h1 className="home_title">
                    WHAT DO WE DO<br></br>
                  </h1>
                  <div style={{ display: "flex" }}>
                    <img
                      src={img30}
                      style={{
                        width: "8rem",
                        height: "min-content",
                        marginRight: "2rem",
                      }}
                    ></img>
                    <p className="experience">
                      We offer end to end facility transformation from upgrading
                      small offices to building world class work facilities
                      accommodating 2000+ people in one premises.
                    </p>
                  </div>

                  {/* <button className="booknow">BOOK NOW</button> */}
                  <ul className="homeul" style={{ marginLeft: "10rem" }}>
                    <li className="homeli">
                      <i className="bi bi-check2-all homei"></i>{" "}
                      <span>Turnkey project office renovation </span>
                    </li>
                    <li className="homeli">
                      <i className="bi bi-check2-all homei"></i>{" "}
                      <span>
                        Office upgrade including work stations & cubicles
                      </span>
                    </li>
                    <li className="homeli">
                      <i className="bi bi-check2-all homei"></i>{" "}
                      <span>Electrical Automation & Audio Video Solutions</span>
                    </li>
                    <li className="homeli">
                      <i className="bi bi-check2-all homei"></i>{" "}
                      <span>Conference Halls and Training Centre Upgrades</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* counter */}
          <div className="containerr section-bg-mr">
            <div className="background-image"></div>
            <div className="text">
              <h1 className="experienceh1">
                {" "}
                {/* <i className="bi bi-quote quote-icon-left"></i> */}
              </h1>
              <section id="stats-counter" className="stats-counter ">
                <div className="container">
                  <div className="row gy-4">
                    <div className="col-lg-4 col-md-7">
                      <div className="stats-item d-flex align-items-center w-100 h-100">
                        <i className="bi bi-emoji-smile color-blue flex-shrink-0"></i>
                        <div >
                          <span
                            data-purecounter-start="0"
                            data-purecounter-end="12"
                            data-purecounter-duration="1"
                            className="purecounter"
                          ></span>
                          <h6 style={{ fontSize: "14px" }}>
                            No of years in service
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-7">
                      <div className="stats-item d-flex align-items-center w-100 h-100">
                        <i className="bi bi-journal-richtext color-orange flex-shrink-0"></i>
                        <div style={{ padding: "20px" }}>
                          <span
                            data-purecounter-start="0"
                            data-purecounter-end={76}
                            data-purecounter-duration="1"
                            className="purecounter"
                          ></span>
                          <h6 style={{ fontSize: "14px" }}>Projects</h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-7">
                      <div className="stats-item d-flex align-items-center w-100 h-100">
                        <i className="fa-solid fa-city"></i>
                        <div>
                          <span
                            data-purecounter-start="0"
                            data-purecounter-end="10"
                            data-purecounter-duration="1"
                            className="purecounter"
                          ></span>
                          <h6 style={{ fontSize: "14px" }}>No of City's</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* <section
          id="recent-blog-posts"
          className="recent-blog-posts roomsuites"
        >
          <div className="container" data-aos="fade-up">
            <div className=" section-header">
              <h1>Rooms & Suites</h1>

              <p className="h4 experienceh1">
                <i className="bi bi-dot"></i>
              </p>
            </div>

            <div className="row gy-5 ">
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="post-item position-relative h-100">
                  <div className="post-img position-relative overflow-hidden">
                    <img src={img3} className="img-fluid" alt=""></img>
                    <span className="post-date">
                      $49/night{" "}
                      <h3>
                        <i className="bi bi-circle experienceh1"></i> Standart
                        Room
                      </h3>{" "}
                    </span>
                    <div className="hrcenter">
                      <hr className="full-width-linee"></hr>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="post-item position-relative h-100">
                  <div className="post-img position-relative overflow-hidden">
                    <img src={img6} className="img-fluid" alt=""></img>
                    <span className="post-date">
                      $69/night{" "}
                      <h3>
                        {" "}
                        <i className="bi bi-circle experienceh1"></i> Deluxe
                        Room
                      </h3>
                    </span>
                    <div className="hrcenter">
                      <hr className="full-width-linee"></hr>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div
                  className="post-item position-relative h-100"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="post-img position-relative overflow-hidden">
                    <img src={img5} className="img-fluid" alt=""></img>
                    <span className="post-date">
                      $99/night{" "}
                      <h3>
                        <i className="bi bi-circle experienceh1"></i> Luxary
                        Room
                      </h3>
                    </span>

                    <div className="hrcenter">
                      <hr className="full-width-linee"></hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

          {/* Awards */}
          <section
            id="recent-blog-posts"
            className="recent-blog-posts  section-bg-mr section-bg"
          >
            <div className="container" data-aos="fade-up">
              <div className=" section-header">
                <h2 className="home_title"> Awards</h2>

                {/* <p className="h4 experienceh1">
                <i className="bi bi-dot"></i>
              </p> */}
              </div>

              <div className="">
                <div className="service-area space" id="service-sec">
                  <div className="container">
                    <div className="row gy-4">
                      {/* awards 1 */}
                      <div className="col-md-6 col-lg-6 col-xl-6 flowhidden">
                        <div
                          className="service-grid  Larger shadow"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="service-grid_icon">
                            {/* <img src="assets/images/products/icons8-awards-64.png" alt="" /> */}
                          </div>
                          <div className="service-grid-content">
                            <h3 className="box-title">
                              <div className="">
                                <a className="text_awards">
                                  100+ Offices Uplifted and Upgraded
                                </a>
                              </div>
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* awards 2 */}
                      <div className="col-md-6 col-lg-6 col-xl-6 flowhidden">
                        <div
                          className="service-grid2 wow fadeInUp Larger shadow"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="service-grid_icon">
                            {/* <img src="assets/images/products/icons8-awards-64.png" alt="" /> */}
                          </div>
                          <div className="service-grid-content">
                            <h3 className="box-title">
                              <div className="">
                                <a className="text_awards">
                                  Delivered Pan India Turnkey Projects
                                </a>
                              </div>
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* award3 */}
                      <div className="col-md-6 col-lg-6 col-xl-6 flowhidden">
                        <div
                          className="service-grid3 wow fadeInUp Larger shadow"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="service-grid_icon">
                            {/* <img src="assets/images/products/icons8-awards-64.png" alt="" /> */}
                          </div>
                          <div className="service-grid-content text_awards">
                            <h3 className="box-title">
                              <a>Delivered VVIP High end Projects for</a>
                            </h3>
                            <h4 className="service-grid_text" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <div style={{ textAlign: "start" }}>
                                <div>
                                  <i className="bi bi-dot"></i> CMD MSETCL Mumbai{" "}
                                </div>
                                <div>
                                  <i className="bi bi-dot"></i> CMD WCL Nagpur HQ
                                </div>
                              </div>
                            </h4>



                          </div>
                        </div>
                      </div>
                      {/* award4 */}
                      <div className="col-md-6 col-lg-6 col-xl-6 flowhidden">
                        <div
                          className="service-grid4 wow fadeInDown Larger shadow"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="service-grid_icon">
                            {/* <img src="assets/images/products/icons8-awards-64.png" alt="" /> */}
                          </div>
                          <div className="service-grid-content">
                            <h3 className="box-title">
                              <a className="text_awards">
                                And many more in pipeline
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
        class="shape-mockup shape-wrapp jump d-none d-xl-block"
        data-top="5%"
        data-left="0%"
      >
        <img src="assets/img/shape/leaves_11.png" alt="shape" />
      </div> */}
              </div>

              {/* <div
              className=" maindiv"
              style={{
                // backgroundColor: "rgb(179 179 179 / 12%)",
                lineHeight: "3rem",
              }}
            >
              <div>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" , margin:'1rem'}}
                >
                  <div
                    className="col-md-5 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                  >
                   <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      width="70"
      viewBox="0 0 384 512"
      className="homei"
      style={{
        fill: isHovered ? "white" : "#00deff",
        transition: "fill 0.3s",
      }}
    

    >
      <path
        d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"
      />
    </svg>

                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      100+ Offices Uplifted and Upgraded.
                    </p>
                  </div>
                  <div
                    className="col-md-5 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                    onMouseOver={() => setIsHovered2(true)}
                    onMouseOut={() => setIsHovered2(false)}
                  >
                       <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      width="70"
      viewBox="0 0 384 512"
      className="homei"
      style={{
        fill: isHovered2 ? "white" : "#00deff",
        transition: "fill 0.3s",
      }}
    

    >
      <path
        d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"
      />
    </svg>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      Delivered Pan India Turnkey Projects.
                    </p>
                  </div>
                  <div
                    className="col-md-5 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                    onMouseOver={() => setIsHovered3(true)}
                    onMouseOut={() => setIsHovered3(false)}
                  >
                   <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      width="70"
      viewBox="0 0 384 512"
      className="homei"
      style={{
        fill: isHovered3 ? "white" : "#00deff",
        transition: "fill 0.3s",
      }}
    

    >
      <path
        d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"
      />
    </svg>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      Delivered VVIP High end Projects for.
                    </p>
                    <ul style={{ lineHeight: "2rem", display: "inline" }}>
                      <li style={{ marginLeft: "3rem" }}>
                        <i class="bi bi-dot"></i>{" "}
                        <span
                          style={{
                            fontSize: "14px",
                            letterSpacing: "normal",
                            fontFamily: "initial",
                          }}
                        >
                          l CMD MSETCL Mumbai
                        </span>
                      </li>
                      <li style={{ marginLeft: "3rem" }}>
                        <i class="bi bi-dot"></i>
                        <span
                          style={{
                            fontSize: "14px",
                            letterSpacing: "normal",
                            fontFamily: "initial",
                          }}
                        >
                          {" "}
                          CMD WCL Nagpur HQ
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-md-5 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                    onMouseOver={() => setIsHovered4(true)}
                    onMouseOut={() => setIsHovered4(false)}
                  >
                      <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      width="70"
      viewBox="0 0 384 512"
      className="homei"
      style={{
        fill: isHovered4? "white" : "#00deff",
        transition: "fill 0.3s",
      }}
    

    >
      <path
        d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z"
      />
    </svg>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      And many more in pipeline.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </section>


          {/* Achievement */}
          <section
            id="recent-blog-posts"
            className="recent-blog-posts  section-bg-mr "
          >
            <div className="container" data-aos="fade-up">
              <div className=" section-header">
                <h2 className="home_title">Achievement</h2>
                <h4>
                  100+ offices redo.Handed over VVIP High End Projects of CMD MSETCL Mumbai and CMD WCL Nagpur HQ.
                </h4>
              </div>
            </div>
          </section>


          {/* <div className="containerr2 ">
          <div className="background-image2"></div>
          <div className="text2">
            <div className="experienceh1">
            
            </div>
            <div data-aos="fade-up ">
              <h2 className="testimonial-text">
                Enjoy Luxary Hotel Experience Like Never Before
              </h2>
            </div>
            <p className="mt-3">
              {" "}
              <span className="star-rating">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={star <= rating ? "star-filled" : "star-empty"}
                    onClick={() => handleStarClick(star)}
                  >
                    &#9733;
                  </span>
                ))}
              </span>
            </p>
          </div>
        </div> */}

          {/* projects */}
          <div className="services ">
            <div className="services-items">
              <section
                id="services"
                className="services section-bg section-bg-mr"
              >
                <div className="container" data-aos="fade-up">
                  <div className="section-header">
                    <h2>PROJECTS</h2>
                    <h4 className="mt-5">
                      DELIVERING END TO END PROJECT MANAGEMENT
                    </h4>
                  </div>

                  <div className="row gy-4">
                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="service-item  position-relative">
                        <div
                          className="project_top_image"
                          style={{
                            backgroundImage: `url(${img34})`,
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {/* <i className="fa-solid fa-mountain-city"></i> */}
                        </div>
                        <h3 style={{ fontSize: "1.5rem" }}>
                          Office Renovations & Upgradation
                        </h3>
                        <p>
                          {showMore1 ? content : `${content.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(1)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div className="service-item position-relative">
                        <div
                          className="project_top_image"
                          style={{
                            backgroundImage: `url(${img})`,
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <i className="fa-solid fa-compass-drafting"></i>
                        </div>
                        <h3>Conference Halls</h3>
                        <p>
                          {showMore2
                            ? content2
                            : `${content2.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(2)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <div className="service-item position-relative">
                        <div
                          className="project_top_image"
                          style={{
                            backgroundImage: `url(${img31})`,
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <i className="fa-solid fa-trowel-bricks"></i>
                        </div>
                        <h3>Training Rooms</h3>
                        <p>
                          {showMore3
                            ? content3
                            : `${content3.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(3)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="service-item position-relative">
                        <div
                          className="project_top_image"
                          style={{
                            backgroundImage: `url(${img32})`,
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <i className="fa-solid fa-arrow-up-from-ground-water"></i>
                        </div>
                        <h3>Canteen Renovation</h3>
                        <p>
                          {showMore4
                            ? content4
                            : `${content4.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(4)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <div className="service-item position-relative">
                        <div
                          className="project_top_image"
                          style={{
                            backgroundImage: `url(${img33})`,
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <i className="fa-solid fa-helmet-safety"></i>
                        </div>
                        <h3>Office Interiors</h3>
                        <p>
                          {showMore5
                            ? content5
                            : `${content5.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(5)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <div className="service-item position-relative">
                        <div
                          className="project_top_image"
                          style={{
                            backgroundImage: `url(${img35})`,
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {/* <i className="fa-solid fa-arrow-up-from-ground-water"></i> */}
                        </div>
                        <h3>Mega Stores</h3>
                        <p>
                          {showMore6
                            ? content6
                            : `${content6.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(6)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </section>
              {/* <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div
                  className="col-lg-6 img-bg"
                  style={{
                    backgroundImage: `url(${img2})`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>

                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3>
                  <p>
                    Esse voluptas cumque vel exercitationem. Reiciendis est hic
                    accusamus. Non ipsam et sed minima temporibus laudantium.
                    Soluta voluptate sed facere corporis dolores excepturi
                  </p>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Lorem Ipsum
                        </a>
                      </h4>
                      <p>
                        Voluptatum deleniti atque corrupti quos dolores et quas
                        molestias excepturi sint occaecati cupiditate non
                        provident
                      </p>
                    </div>
                  </div>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Nemo Enim
                        </a>
                      </h4>
                      <p>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque
                      </p>
                    </div>
                  </div>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Dine Pad
                        </a>
                      </h4>
                      <p>
                        Explicabo est voluptatum asperiores consequatur magnam.
                        Et veritatis odit. Sunt aut deserunt minus aut eligendi
                        omnis
                      </p>
                    </div>
                  </div>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i className="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Tride clov
                        </a>
                      </h4>
                      <p>
                        Est voluptatem labore deleniti quis a delectus et. Saepe
                        dolorem libero sit non aspernatur odit amet. Et eligendi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>*/}
              {/* <section id="features" className="features section-bg">
            <div className="container" data-aos="fade-up">
              <ul className="nav nav-tabs row  g-2 d-flex">
                <li className="nav-item col-3">
                  <a
                    className="nav-link active show"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-1"
                  >
                    <h4>Modisit</h4>
                  </a>
                </li>

                <li className="nav-item col-3">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-2"
                  >
                    <h4>Praesenti</h4>
                  </a>
                </li>

                <li className="nav-item col-3">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-3"
                  >
                    <h4>Explica</h4>
                  </a>
                </li>

                <li className="nav-item col-3">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-4"
                  >
                    <h4>Nostrum</h4>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane active show" id="tab-1">
                  <div className="row">
                    <div
                      className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <h3>Voluptatem dignissimos provident</h3>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate trideta
                          storacalaperda mastiro dolore eu fugiat nulla
                          pariatur.
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-lg-6 order-1 order-lg-2 text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <img src={img3} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-2">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                      <h3>Neque exercitationem debitis</h3>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Provident
                          mollitia neque rerum asperiores dolores quos qui a.
                          Ipsum neque dolor voluptate nisi sed.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate trideta
                          storacalaperda mastiro dolore eu fugiat nulla
                          pariatur.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img src={img4} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-3">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                      <h3>Voluptatibus commodi accusamu</h3>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Provident
                          mollitia neque rerum asperiores dolores quos qui a.
                          Ipsum neque dolor voluptate nisi sed.
                        </li>
                      </ul>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img src={img5} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-4">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                      <h3>Omnis fugiat ea explicabo sunt</h3>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate trideta
                          storacalaperda mastiro dolore eu fugiat nulla
                          pariatur.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img src={img6} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>  */}
            </div>
          </div>


          {/* on going  projects */}
          <div className="services ">
            <div className="services-items">
              <section
                id="services"
                className="services section-bg section-bg-mr"
              >
                <div className="container" data-aos="fade-up">
                  <div className="container" data-aos="fade-up">
                    <div className=" section-header ">
                      <h5
                        className="home_title"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        ONGOING PROJECT
                      </h5>
                      <p className="h4 experienceh1">
                        <i className="bi bi-dot"></i>
                      </p>
                      {/* <h4 className="mt-5">
                        Deliver a cutting edge office design by blending elegance and
                        classy that provide high functionality and sustainability
                        creating a go to lace for work.
                      </h4> */}
                    </div>
                  </div>

                  <div className="row gy-4">


                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <div className="service-item position-relative">
                        <video
                          autoPlay
                          loop
                          muted  // Add this line
                          src={img36}
                          style={{
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat"
                          }}
                        ></video>


                        <div
                          className="project_top_image"

                        >
                          {/* <i className="fa-solid fa-arrow-up-from-ground-water"></i> */}
                        </div>
                        <h3>Bilaspur SECL CMD</h3>
                        <p class="animate-me">On going project</p>
                        {/* <p>
                          {showMore6
                            ? content6
                            : `${content6.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(6)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a> */}
                      </div>
                    </div>

                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <div className="service-item position-relative">
                        <video
                          autoPlay
                          loop
                          muted  // Add this line
                          src={img36}
                          style={{
                            height: "14rem",
                            width: "auto",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat"
                          }}
                        ></video>


                        <div
                          className="project_top_image"

                        >
                          {/* <i className="fa-solid fa-arrow-up-from-ground-water"></i> */}
                        </div>
                        <h3>Baidhyanath Group</h3>
                        <p class="animate-me">On going project</p>
                        {/* <p>
                          {showMore6
                            ? content6
                            : `${content6.slice(0, 100)}...`}
                        </p>
                        <a
                          className="readmore stretched-link"
                          onClick={() => toggleShowMore(6)}
                        >
                          Learn more <i className="bi bi-arrow-right"></i>
                        </a> */}
                      </div>
                    </div>

                  </div>
                </div>
              </section>
              {/* <section id="alt-services" className="alt-services">
            <div className="container" data-aos="fade-up">
              <div className="row justify-content-around gy-4">
                <div
                  className="col-lg-6 img-bg"
                  style={{
                    backgroundImage: `url(${img2})`,
                  }}
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>

                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3>
                  <p>
                    Esse voluptas cumque vel exercitationem. Reiciendis est hic
                    accusamus. Non ipsam et sed minima temporibus laudantium.
                    Soluta voluptate sed facere corporis dolores excepturi
                  </p>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Lorem Ipsum
                        </a>
                      </h4>
                      <p>
                        Voluptatum deleniti atque corrupti quos dolores et quas
                        molestias excepturi sint occaecati cupiditate non
                        provident
                      </p>
                    </div>
                  </div>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Nemo Enim
                        </a>
                      </h4>
                      <p>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque
                      </p>
                    </div>
                  </div>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Dine Pad
                        </a>
                      </h4>
                      <p>
                        Explicabo est voluptatum asperiores consequatur magnam.
                        Et veritatis odit. Sunt aut deserunt minus aut eligendi
                        omnis
                      </p>
                    </div>
                  </div>

                  <div
                    className="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i className="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="" className="stretched-link">
                          Tride clov
                        </a>
                      </h4>
                      <p>
                        Est voluptatem labore deleniti quis a delectus et. Saepe
                        dolorem libero sit non aspernatur odit amet. Et eligendi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>*/}
              {/* <section id="features" className="features section-bg">
            <div className="container" data-aos="fade-up">
              <ul className="nav nav-tabs row  g-2 d-flex">
                <li className="nav-item col-3">
                  <a
                    className="nav-link active show"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-1"
                  >
                    <h4>Modisit</h4>
                  </a>
                </li>

                <li className="nav-item col-3">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-2"
                  >
                    <h4>Praesenti</h4>
                  </a>
                </li>

                <li className="nav-item col-3">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-3"
                  >
                    <h4>Explica</h4>
                  </a>
                </li>

                <li className="nav-item col-3">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-4"
                  >
                    <h4>Nostrum</h4>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane active show" id="tab-1">
                  <div className="row">
                    <div
                      className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <h3>Voluptatem dignissimos provident</h3>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate trideta
                          storacalaperda mastiro dolore eu fugiat nulla
                          pariatur.
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-lg-6 order-1 order-lg-2 text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <img src={img3} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-2">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                      <h3>Neque exercitationem debitis</h3>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Provident
                          mollitia neque rerum asperiores dolores quos qui a.
                          Ipsum neque dolor voluptate nisi sed.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate trideta
                          storacalaperda mastiro dolore eu fugiat nulla
                          pariatur.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img src={img4} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-3">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                      <h3>Voluptatibus commodi accusamu</h3>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Provident
                          mollitia neque rerum asperiores dolores quos qui a.
                          Ipsum neque dolor voluptate nisi sed.
                        </li>
                      </ul>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img src={img5} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-4">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                      <h3>Omnis fugiat ea explicabo sunt</h3>
                      <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Duis aute irure
                          dolor in reprehenderit in voluptate velit.
                        </li>
                        <li>
                          <i className="bi bi-check2-all"></i> Ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Duis aute
                          irure dolor in reprehenderit in voluptate trideta
                          storacalaperda mastiro dolore eu fugiat nulla
                          pariatur.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img src={img6} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>  */}
            </div>
          </div>




          {/* our clients */}
          <section>
            <div className=" section-header">
              <h2 className="home_title">Our Clients</h2>
            </div>

            <div
              className="row maindiv"
              style={{ lineHeight: "3rem", padding: "5rem" }}
            >
              <Carousel
                responsive={responsive}
                style={{ margin: "5rem" }}
                infinite={true}
                autoPlay={true}
              >
                <img
                  src={img11}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 1"
                />
                <img
                  src={img12}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 2"
                />
                <img
                  src={img13}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 3"
                />
                <img
                  src={img14}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 4"
                />
                <img
                  src={img15}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 5"
                />
                <img
                  src={img16}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 6"
                />
                <img
                  src={img17}
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  alt="Image 7"
                />
              </Carousel>
            </div>
          </section>

          {/* client success */}

          <section
            id="recent-blog-posts"
            className="recent-blog-posts section-bg"
          >
            <div className="container" data-aos="fade-up">
              <div className=" section-header">
                <h2 className="titlename">CLIENT SUCCESS</h2>
                <h4 className="mt-5">
                  Competition always make you stronger and better
                </h4>
                {/* <p className="h4 experienceh1">
                <i className="bi bi-dot"></i>
              </p> */}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="flowhidden">
                  <div
                    className="containerr_video "
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={img31}
                      style={{ height: "20rem" }}
                      className="img-fluid"
                    ></img>
                    <a
                      id="play-video"
                      className="video-play-button centered"
                      href={Video}
                    >
                      <span></span>
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="row maindiv mt-5">
                <div className="col-md-4 mainfeatures ">
                  <div className="marginfeatures">
                    <h4 className="featuresheading mb-4">
                      <img src={img24}></img> RISK BASED APPROACH
                    </h4>
                    <p className="features" style={{ marginLeft: "2.4rem" }}>
                      OUR APPROACH IS HIGH STAKE RISK WITH HIGHER RETURN
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mainfeatures ">
                  <div className="marginfeatures">
                    <h4 className="featuresheading mb-4">
                      <img src={img24}></img> EXPERIENCE
                    </h4>
                    <p className="features" style={{ marginLeft: "2.4rem" }}>
                      AVERAGE EXPERIENCE OF A PARTNERS IN THE FIRM IS AROUND 20
                      YEARS
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mainfeatures ">
                  <div className="marginfeatures">
                    <h5 className="featuresheading mb-4">
                      <img src={img24}></img> BUSINESS VALUE DELIVERY
                    </h5>
                    <p className="features" style={{ marginLeft: "2.4rem" }}>
                      OUR FOCUS IS IN ADDING VALUE TO THE ORGANISATIONS WE WORK
                      WITH
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mainfeatures ">
                  <div className="marginfeatures">
                    <h4 className="featuresheading mb-4">
                      <img src={img24}></img> CERTIFIED EXPERTS
                    </h4>
                    <p className="features" style={{ marginLeft: "2.4rem" }}>
                      OUR CONSULTANTS ARE ACCREDITED FROM NATIONALLY RANKED
                      INSITUTIONS
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mainfeatures">
                  <div className="marginfeatures">
                    <h5 className="featuresheading mb-4">
                      <img src={img24}></img> COMMITMENT TO QUALITY
                    </h5>
                    <p className="features" style={{ marginLeft: "2.4rem" }}>
                      ALWAYS DOING THE RIGHT THING FIRST
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mainfeatures ">
                  <div className="marginfeatures">
                    <h4 className="featuresheading mb-4">
                      <img src={img24}></img> 24/7 Security
                    </h4>
                    <p className="features" style={{ marginLeft: "2.4rem" }}>
                      PAN INDIA PRESENCE WITH ACCESS TO NATIONAL EXPERTISE
                      THROUGH OUR ASSOCIATION.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* services */}

          <section id="services" className="services">
            <div className=" section-header">
              <h2 className="home_title home_title_6">OUR 6 CORE PRINCIPLES</h2>
            </div>
            <div className="container">
              <div>
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem",
                  }}
                >
                  <div
                    className="col-md-3 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                  >
                    <img
                      className=" homei"
                      src={img18}
                      style={{
                        marginBottom: "2rem",
                        width: "30%",
                        height: "min-content",
                      }}
                    ></img>{" "}
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      ALWAYS DO THE RIGHT THING
                    </p>
                  </div>

                  <div
                    className="col-md-3 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                  >
                    <img
                      className=" homei"
                      src={img19}
                      style={{
                        marginBottom: "2rem",
                        width: "30%",
                        height: "min-content",
                      }}
                    ></img>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      ETHICAL CONDUCT
                    </p>
                  </div>

                  <div
                    className="col-md-3 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                  >
                    <img
                      className=" homei"
                      src={img20}
                      style={{
                        marginBottom: "2rem",
                        width: "30%",
                        height: "min-content",
                      }}
                    ></img>{" "}
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      QUALITY FIRST
                    </p>
                  </div>

                  <div
                    className="col-md-3 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                  >
                    <img
                      className=" homei"
                      src={img21}
                      style={{
                        marginBottom: "2rem",
                        width: "30%",
                        height: "min-content",
                      }}
                    ></img>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      CONTINUOUS LEARNING
                    </p>
                  </div>

                  <div
                    className="col-md-3 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                    }}
                  >
                    <img
                      className=" homei"
                      src={img22}
                      style={{
                        marginBottom: "2rem",
                        width: "30%",
                        height: "min-content",
                      }}
                    ></img>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      PROFESSIONAL SKEPTICISM
                    </p>
                  </div>

                  <div
                    className="col-md-3 awards"
                    style={{
                      backgroundColor: "white",
                      margin: "2rem",
                      flexFlow: "column",
                      textAlign: "center",
                    }}
                  >
                    <img
                      className=" homei"
                      src={img23}
                      style={{
                        marginBottom: "2rem",
                        width: "30%",
                        height: "min-content",
                      }}
                    ></img>{" "}
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "550",
                        display: "contents",
                        fontFamily: "initial",
                      }}
                    >
                      {" "}
                      TIMELY MEETING CLIENT COMMITMENTS
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Home;
