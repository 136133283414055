import React from "react";
import "../Css/fotter.css";
import img from '../Image/Dark_Beige_Modern_Real_Estate_Bu.png'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer">
        <div className="footer-content position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div>
                  <div className="mb-3">
                    <img src={img} className="logoo"></img>
                    <div className="fotteralign">

                      {/* <div className="fotter">
                        <h2 className="footername">THE ARCHI</h2>
                        <h6 className="middelline">
                          <span> Hotel & Restort</span>
                        </h6>{" "}
                      </div> */}
                    </div>
                    <p style={{ fontSize: "12px", textAlign: "justify" }} className="footertext">
                      {/* add details here */}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-3 footer-links ">
                <div className="fotteralign">
                  <h4 className="text-align-center">Latest News</h4>
                </div>
                <p style={{ fontSize: "12px" }} className="footertext marg">
                  The Essentials interior Design Tips Functional Wall Shevels
                  Wall-to-Wall Shelves Deluxe Room 9 Unique Ways to Display Your
                  TV Luxury Room The 5 Secrets to Minimal Design Family Room
                  Make a Huge Impact With Multiples
                </p>
              </div> */}


              <div className="col-lg-3  col-md-4 copyright ">
                <h4 className="">Quick Links</h4>
                <div className="fotter_nav">

                  <Link
                    style={{ fontSize: "12px" }}
                    className="footertext "
                    to="/"
                  >
                    <span style={{ color: "#00deff" }}>  <i class="bi bi-arrow-right"></i> </span>  Home
                  </Link>

                  <Link
                    style={{ fontSize: "12px" }}
                    className="footertext "
                    to="/about"
                  >
                    <span style={{ color: "#00deff" }}>     <i class="bi bi-arrow-right"></i></span>   About
                  </Link>

                  <Link
                    style={{ fontSize: "12px" }}
                    className="footertext "
                    to="/ClientsTestimonial"
                  >
                    <span style={{ color: "#00deff" }}>  <i class="bi bi-arrow-right"></i> </span> Testimonial
                  </Link>

                  <Link
                    style={{ fontSize: "12px" }}
                    className="footertext"
                    to="/Project"
                  >
                    <span style={{ color: "#00deff" }}>   <i class="bi bi-arrow-right"></i>   </span>     Project
                  </Link>

                  <Link
                    style={{ fontSize: "12px" }}
                    className="footertext "
                    to="/contact"
                  >
                    <span style={{ color: "#00deff" }}>    <i class="bi bi-arrow-right"></i> </span>  Contact
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 footer-links">
                <div>
                  <h4 className="">Contact us</h4>
                  <div>
                    <p
                      style={{ fontSize: "12px" }}
                      className="footertext"
                      href="#"
                    >
                      53 Raksha Colony, Near Fire Engineering Colony, Nagpur
                    </p>
                  </div>

                  <div>
                    <p
                      style={{ fontSize: "12px" }}
                      className="footertext"
                      href="#"
                    >
                      {" "}
                      <span className="experienceh1"><i className="bi bi-telephone-fill"></i> </span>
                      + 91 744 7491 009
                    </p>
                  </div>



                  <div>
                    <p
                      style={{ fontSize: "12px" }}
                      className="footertext"
                      href="#"
                    >
                      {" "}
                      <span className="experienceh1"> <i className="bi bi-envelope-at-fill"></i>  </span>
                      <a href="mailto: vks_orgnz@rediffmail.com">vks_orgnz@rediffmail.com   </a>
                    </p>
                  </div>

                  <div>
                    <p
                      style={{ fontSize: "12px" }}
                      className="footertext"
                      href="#"
                    >
                      {" "}
                      <span className="experienceh1"><i className="bi bi-globe"></i> </span>
                      <a href="https://vks.pranam.co.in/">https://vks.pranam.co.in/</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-width-line"></hr>
        <div className=" position-relative  marginfooter">
          <div className="row m-0">
            <div className="col-md-6">
              <div className="footerr ">
                <div className="copyrigh">
                  © Copyright VKS Organisation. All Rights Reserved
                  {/* <span className="experienceh1"> VKS Organisation</span>  */}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="socialiconn ">
                <i className="fa-brands fa-facebook footersocial"></i>
                <i className="fa-brands fa-instagram footersocial"></i>
                <i className="fa-brands fa-twitter footersocial"></i>

                <i className="fa-brands fa-linkedin footersocial"></i>

              </div>
            </div>
          </div>


        </div>
      </footer>
    </div>
  );
};

export default Footer;
